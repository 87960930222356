import { SuggestedAction } from "localTypes";
import { DEFAULT_MRS_VALUE, AVERAGE_MRS_QUOTE_THRESHOLD } from "app-constants";
export var mrsThreshold = {
    evaluate: function (provider, submission) {
        var _a;
        var validMrs = ((_a = submission.isQuickAuditEnabled) !== null && _a !== void 0 ? _a : true) ? "mrs" : "manualMrs";
        if (((provider === null || provider === void 0 ? void 0 : provider[validMrs]) || DEFAULT_MRS_VALUE) > AVERAGE_MRS_QUOTE_THRESHOLD) {
            return SuggestedAction.Review;
        }
        return SuggestedAction.Quote;
    },
    success: function () { return "Provider MRS Below Threshold"; },
    warning: function () { return "Provider MRS Above Threshold"; },
    affectsGroup: false,
};
