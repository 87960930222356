var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Intent, SuggestedAction, } from "localTypes";
import { rules } from "./rules";
var AppetiteEngine = /** @class */ (function () {
    function AppetiteEngine() {
        this.groupRules = [];
        this.individualRules = [];
    }
    AppetiteEngine.prototype.addSubmissionRule = function (rule) {
        this.groupRules.push(rule);
    };
    AppetiteEngine.prototype.addProviderRule = function (rule) {
        this.individualRules.push(rule);
    };
    AppetiteEngine.prototype.evaluateProvider = function (provider, context) {
        var _this = this;
        var results = this.individualRules.map(function (rule) {
            var action = rule.evaluate(provider, context);
            var message = _this.getMessageForAction(rule, action);
            return { action: action, messages: [message], affectsGroup: rule.affectsGroup };
        });
        var combinedResult = this.combineResults(results);
        return __assign(__assign({}, combinedResult), { id: provider.id, affectsGroup: results.some(function (r) { return r.affectsGroup && r.action === SuggestedAction.Review; }) });
    };
    AppetiteEngine.prototype.evaluateSubmission = function (submission) {
        var _this = this;
        var _a;
        if (!(submission === null || submission === void 0 ? void 0 : submission.providers) || ((_a = submission === null || submission === void 0 ? void 0 : submission.providers) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return;
        }
        var individualResults = submission === null || submission === void 0 ? void 0 : submission.providers.map(function (provider) {
            return _this.evaluateProvider(provider, submission);
        });
        var groupResults = this.groupRules.map(function (rule) {
            var action = rule.evaluate(submission);
            var message = _this.getMessageForAction(rule, action);
            return { action: action, messages: [message], affectsGroup: rule.affectsGroup };
        });
        var combinedResult = this.combineGroupResults(individualResults, groupResults);
        return __assign(__assign({}, combinedResult), { providers: individualResults });
    };
    AppetiteEngine.prototype.combineResults = function (results) {
        var action = this.getHighestAction(results.map(function (r) { return r.action; }));
        var messages = this.deduplicateMessages(results.flatMap(function (r) { return r.messages; }));
        return { action: action, messages: messages };
    };
    AppetiteEngine.prototype.combineGroupResults = function (individualResults, groupResults) {
        var topLevelAction = this.getTopLevelAction(groupResults.map(function (r) { return r.action; }), individualResults
            .filter(function (r) { return r.affectsGroup; })
            .map(function (r) {
            if (r.action === SuggestedAction.Decline) {
                return SuggestedAction.Review;
            }
            return r.action;
        }));
        return {
            action: topLevelAction,
            messages: this.sortByIntent(this.deduplicateMessages(__spreadArray(__spreadArray([], individualResults
                .filter(function (r) { return r.affectsGroup; })
                .flatMap(function (r) {
                return r.messages.map(function (m) {
                    if (m.intent === Intent.Error) {
                        return __assign(__assign({}, m), { intent: Intent.Warning });
                    }
                    return m;
                });
            }), true), groupResults.flatMap(function (r) { return r.messages; }), true))),
        };
    };
    AppetiteEngine.prototype.sortByIntent = function (messages) {
        var intentOrder = {
            error: 0,
            warning: 1,
            success: 2,
        };
        return messages.sort(function (a, b) { return intentOrder[a.intent] - intentOrder[b.intent]; });
    };
    AppetiteEngine.prototype.deduplicateMessages = function (messages) {
        var uniqueMessages = new Set();
        return messages.filter(function (message) {
            var key = "".concat(message.intent, ":").concat(message.text);
            if (!uniqueMessages.has(key)) {
                uniqueMessages.add(key);
                return true;
            }
            return false;
        });
    };
    AppetiteEngine.prototype.getMessageForAction = function (rule, action) {
        var _a;
        switch (action) {
            case SuggestedAction.Quote:
                return { intent: Intent.Success, text: rule.success() };
            case SuggestedAction.Review:
                return { intent: Intent.Warning, text: rule.warning() };
            case SuggestedAction.Decline:
                return { intent: Intent.Error, text: ((_a = rule.error) === null || _a === void 0 ? void 0 : _a.call(rule)) || rule.warning() };
        }
    };
    AppetiteEngine.prototype.getHighestAction = function (actions) {
        if (actions.includes(SuggestedAction.Decline))
            return SuggestedAction.Decline;
        if (actions.includes(SuggestedAction.Review))
            return SuggestedAction.Review;
        return SuggestedAction.Quote;
    };
    AppetiteEngine.prototype.getTopLevelAction = function (groupActions, individualActions) {
        if (groupActions.includes(SuggestedAction.Decline)) {
            return SuggestedAction.Decline;
        }
        else if (groupActions.includes(SuggestedAction.Review)) {
            return SuggestedAction.Review;
        }
        else if (individualActions.includes(SuggestedAction.Decline)) {
            return SuggestedAction.Review;
        }
        else if (individualActions.includes(SuggestedAction.Review)) {
            return SuggestedAction.Review;
        }
        return SuggestedAction.Quote;
    };
    return AppetiteEngine;
}());
export { AppetiteEngine };
var appetiteEngine = new AppetiteEngine();
Object.keys(rules.submission).forEach(function (ruleKey) {
    appetiteEngine.addSubmissionRule(rules.submission[ruleKey]);
});
Object.keys(rules.provider).forEach(function (ruleKey) {
    appetiteEngine.addProviderRule(rules.provider[ruleKey]);
});
export { appetiteEngine };
