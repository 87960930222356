var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useState, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ApiClientContext } from "providers";
import { useMutation } from "@apollo/client";
import { CALCULATE_INSIGHTS_DATA, UPDATE_PROVIDERS } from "mutations";
import { GET_SUBMISSION } from "queries";
import { useToggleQuickAudit } from "hooks";
import { INDIGO_SPECIALTIES, DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE, DEFAULT_MRS_VALUE, } from "app-constants";
import { formatSpecialtyInconsistencies, formatLocationInconsistencies, formatBillingData, formatMrsTrends, convertClaimDateToClaim, generateFactors, } from "utils";
export function useModelRatingApi() {
    var _this = this;
    var id = useParams().id;
    var isQuickAuditEnabled = useToggleQuickAudit(id || "").isQuickAuditEnabled;
    var updateProviders = useMutation(UPDATE_PROVIDERS, {
        refetchQueries: [GET_SUBMISSION],
        awaitRefetchQueries: true,
    })[0];
    var calculateInsightsData = useMutation(CALCULATE_INSIGHTS_DATA, {
        refetchQueries: [GET_SUBMISSION],
        awaitRefetchQueries: true,
    })[0];
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(), modelData = _b[0], setModelData = _b[1];
    var apiClient = useContext(ApiClientContext);
    var fetchModelRating = useCallback(function (provider, submissionId) { return __awaiter(_this, void 0, void 0, function () {
        var insights, validSpecialty_1, modelRating, inconsistencies, newMRS, validMrsKey, claims, e_1;
        var _a;
        var _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __generator(this, function (_l) {
            switch (_l.label) {
                case 0:
                    _l.trys.push([0, 8, 9, 10]);
                    setLoading(true);
                    return [4 /*yield*/, calculateInsightsData({
                            variables: {
                                submissionId: id,
                            },
                        })];
                case 1:
                    insights = _l.sent();
                    validSpecialty_1 = isQuickAuditEnabled
                        ? provider === null || provider === void 0 ? void 0 : provider.indigoSpecialty
                        : (provider === null || provider === void 0 ? void 0 : provider.manualIndigoSpecialty) || (provider === null || provider === void 0 ? void 0 : provider.indigoSpecialty);
                    if (!(((_b = provider === null || provider === void 0 ? void 0 : provider.address) === null || _b === void 0 ? void 0 : _b.countyFips) && provider.npi && validSpecialty_1)) return [3 /*break*/, 5];
                    return [4 /*yield*/, (apiClient === null || apiClient === void 0 ? void 0 : apiClient.modelRating({
                            countyFIPS: provider.address.countyFips,
                            npi: provider.npi,
                            specialty: ((_c = INDIGO_SPECIALTIES.find(function (specialty) { return specialty.name === validSpecialty_1; })) === null || _c === void 0 ? void 0 : _c.name) || "",
                        }))];
                case 2:
                    modelRating = _l.sent();
                    setModelData(modelRating);
                    inconsistencies = {
                        location: formatLocationInconsistencies(provider, modelRating),
                        specialty: formatSpecialtyInconsistencies(provider, modelRating),
                    };
                    newMRS = void 0;
                    validMrsKey = isQuickAuditEnabled ? "mrs" : "manualMrs";
                    if ((_e = (_d = modelRating === null || modelRating === void 0 ? void 0 : modelRating.results) === null || _d === void 0 ? void 0 : _d[0]) === null || _e === void 0 ? void 0 : _e.MODEL_RISK_SCORE) {
                        newMRS = parseFloat(modelRating.results[0].MODEL_RISK_SCORE.toFixed(2));
                    }
                    if ((provider === null || provider === void 0 ? void 0 : provider.ignoreLocationInconsistency) === false) {
                        newMRS = DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE;
                    }
                    if ((provider === null || provider === void 0 ? void 0 : provider.newToPractice) === 1) {
                        newMRS = DEFAULT_MRS_VALUE;
                    }
                    claims = JSON.parse(((_g = (_f = modelRating === null || modelRating === void 0 ? void 0 : modelRating.results) === null || _f === void 0 ? void 0 : _f[0]) === null || _g === void 0 ? void 0 : _g.PRIOR_LEGAL_CLAIM_REPORTED_DATES_REPORT) || "[]").map(function (claim) { return convertClaimDateToClaim(claim, provider.npi || ""); });
                    if (!(provider[validMrsKey] !== newMRS ||
                        ((_h = provider === null || provider === void 0 ? void 0 : provider.claims) === null || _h === void 0 ? void 0 : _h.length) !== claims.length ||
                        !(provider === null || provider === void 0 ? void 0 : provider.billingData) ||
                        !(provider === null || provider === void 0 ? void 0 : provider.negativeFactors) ||
                        ((provider === null || provider === void 0 ? void 0 : provider.newToPractice) === 1 &&
                            provider[validMrsKey] !== ((_j = insights === null || insights === void 0 ? void 0 : insights.data.calculateInsightsData) === null || _j === void 0 ? void 0 : _j.averageMrs)))) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateProviders({
                            variables: {
                                submissionId: id === undefined ? submissionId : id,
                                providers: [
                                    (_a = {
                                            id: provider.id
                                        },
                                        _a[validMrsKey] = (provider === null || provider === void 0 ? void 0 : provider.newToPractice) === 1
                                            ? (_k = insights === null || insights === void 0 ? void 0 : insights.data.calculateInsightsData) === null || _k === void 0 ? void 0 : _k.averageMrs
                                            : newMRS,
                                        _a.mrsTrends = formatMrsTrends(modelRating),
                                        _a.billingData = formatBillingData(modelRating),
                                        _a.negativeFactors = generateFactors(modelRating),
                                        _a.inconsistencies = inconsistencies,
                                        _a.claims = claims,
                                        _a),
                                ],
                            },
                        })];
                case 3:
                    _l.sent();
                    _l.label = 4;
                case 4: return [3 /*break*/, 7];
                case 5:
                    if (!!provider.billingData) return [3 /*break*/, 7];
                    return [4 /*yield*/, updateProviders({
                            variables: {
                                submissionId: id === undefined ? submissionId : id,
                                providers: [
                                    {
                                        id: provider.id,
                                        billingData: formatBillingData(),
                                    },
                                ],
                            },
                        })];
                case 6:
                    _l.sent();
                    _l.label = 7;
                case 7: return [2 /*return*/, modelData];
                case 8:
                    e_1 = _l.sent();
                    console.error("Error fetching model rating", e_1);
                    return [3 /*break*/, 10];
                case 9:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 10: return [2 /*return*/];
            }
        });
    }); }, [calculateInsightsData, modelData, id, updateProviders, apiClient, isQuickAuditEnabled]);
    return { loading: loading, modelData: modelData, fetchModelRating: fetchModelRating };
}
