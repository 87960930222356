import { format } from "date-fns";
import { SuggestedAction } from "localTypes";
export var claims = {
    evaluate: function (provider) {
        var _a, _b;
        var claims = (provider === null || provider === void 0 ? void 0 : provider.claims) || [];
        if (claims.length === 0) {
            return SuggestedAction.Quote;
        }
        if (claims.length > 1) {
            return SuggestedAction.Review;
        }
        var lastClaimYear = parseInt(((_b = (_a = claims[0]) === null || _a === void 0 ? void 0 : _a.incidentDate) === null || _b === void 0 ? void 0 : _b.split("/")[2]) || "");
        var currentYear = parseInt(format(new Date(), "yyyy"));
        if (!isNaN(lastClaimYear)) {
            if (currentYear - 10 >= lastClaimYear) {
                return SuggestedAction.Quote;
            }
        }
        return SuggestedAction.Review;
    },
    success: function () { return "No Recent Claims"; },
    warning: function () { return "Check Claims"; },
    affectsGroup: true,
};
