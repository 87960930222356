import { SuggestedAction } from "localTypes";
export var webSearch = {
    evaluate: function (provider) {
        if (Array.isArray(provider.redFlags) && provider.redFlags.length > 0) {
            return SuggestedAction.Review;
        }
        return SuggestedAction.Quote;
    },
    success: function () { return "No Red Flag Results"; },
    warning: function () { return "Check Red Flags"; },
    affectsGroup: true,
};
