import { useCallback, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardBody,
  Checkbox,
  Flex,
  Heading,
  Skeleton,
  Stack,
  Text,
} from "@chakra-ui/react";
import { SubmissionInput, ProviderUpdateInput } from "__generated__/graphql";
import { ResolveForm } from "routes/insights/resolve/ResolveForm";
import { UPDATE_PROVIDERS } from "mutations";
import { GET_SUBMISSION } from "queries";
import { useMutation } from "@apollo/client";
import { useNpiSearch } from "hooks";
import { PROVIDER_SUFFIXES } from "app-constants";

interface ResolutionSelectProps {
  provider: ProviderUpdateInput;
  submissionData: SubmissionInput;
}

export function ResolutionSelect({ submissionData, provider }: ResolutionSelectProps) {
  const [selectedProvider, setSelectedProvider] = useState<{
    npi: string;
    nppesCity: string;
    nppesState: string;
    nppesSpecialty: string;
  } | null>(null);
  const [selectedProviderId, setSelectedProviderId] = useState<string | null>(null);
  const { isLoading, matches, fetchMatches } = useNpiSearch();
  const [updateProviders, { loading }] = useMutation(UPDATE_PROVIDERS, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });

  const handleFormSubmission = useCallback(async () => {
    if (submissionData?.providers && selectedProvider?.npi) {
      await updateProviders({
        variables: {
          submissionId: submissionData.id,
          providers: [{ ...provider, ...selectedProvider }],
        },
      });
    }
  }, [provider, updateProviders, selectedProvider, submissionData.id, submissionData?.providers]);

  useEffect(() => {
    if (
      selectedProvider?.npi !== provider.npi ||
      selectedProvider?.nppesCity !== provider.nppesCity ||
      selectedProvider?.nppesState !== provider.nppesState ||
      selectedProvider?.nppesSpecialty !== provider.nppesSpecialty
    ) {
      handleFormSubmission();
    }
  }, [provider, selectedProvider, handleFormSubmission]);

  useEffect(() => {
    if (matches === undefined) {
      fetchMatches(provider);
    }
    if (matches?.length === 1) {
      const match = matches[0];
      setSelectedProvider({
        npi: match.npi,
        nppesCity: match.city,
        nppesState: match.state,
        nppesSpecialty: match.specialty,
      });
    }
  }, [matches, provider, fetchMatches]);

  function resultInfo() {
    if (isLoading) return;

    if (matches?.length && matches.length > 1) {
      return (
        <Box w="1040px" pb="15px" textAlign="left" pt="10px">
          <Text fontSize="2xl" ml="2px">
            This provider returned multiple results
          </Text>
          <Text fontSize="sm" ml="3px">
            Please select the correct one.
          </Text>
        </Box>
      );
    }
    if (matches?.length === undefined || matches.length === 0) {
      return (
        <Box w="1040px" pb="15px" textAlign="left" pt="10px">
          <Text fontSize="2xl" ml="2px">
            No results found for this provider
          </Text>
          <Text fontSize="sm" ml="3px">
            Please double check their information and re-submit.
          </Text>
        </Box>
      );
    }
  }

  return isLoading ? (
    <Card
      bgColor="white"
      borderRadius="3px"
      variant="outline"
      pb="32px"
      mb="16px"
      w="100%"
      pr="10px"
    >
      <CardBody>
        <Stack data-testid="resolution-select-skeleton">
          <Skeleton height="20px" w="50%" />
          <Skeleton height="20px" mb="20px" />
          <Skeleton height="40px" mb="20px" />
        </Stack>
      </CardBody>
    </Card>
  ) : (
    <>
      {resultInfo()}
      {matches && matches.length > 0 ? (
        <Card
          maxW="1040px"
          bgColor="white"
          borderRadius="3px"
          variant="outline"
          pb="16px"
          mb="16px"
          w="100%"
        >
          <CardBody pt="8px">
            <Box>
              <Heading size="xl" fontWeight="medium">
                {provider.firstName} {provider.lastName}
                {provider.suffix === "" ? "" : `, ${PROVIDER_SUFFIXES[provider.suffix || ""]}`}
              </Heading>
              {matches?.map((match, index) => {
                return (
                  <Checkbox
                    aria-label={match.providerName}
                    key={index}
                    bgColor="indigo.100"
                    my="8px"
                    w="100%"
                    alignItems="top"
                    borderRadius="3px"
                    borderColor="gray.200"
                    borderWidth="1px"
                    onChange={() => {
                      setSelectedProviderId(match.npi);
                      setSelectedProvider({
                        npi: match.npi,
                        nppesCity: match.city,
                        nppesState: match.state,
                        nppesSpecialty: match.specialty,
                      });
                    }}
                    p="12px"
                    colorScheme="indigo"
                    isChecked={
                      !loading ? provider.npi === match.npi : selectedProviderId === match.npi
                    }
                    style={{ width: "100%" }}
                  >
                    <Flex key={index} textAlign="left" gap="8px" w="100%">
                      <Flex direction="column" w="100%">
                        <Flex justifyContent="space-between" w="100%">
                          <Text fontSize="sm" lineHeight="tall" mb="-5px" fontWeight="semibold">
                            {match.providerName}
                          </Text>
                          <Text
                            textAlign="right"
                            fontSize="sm"
                            lineHeight="tall"
                            mb="-5px"
                            fontWeight="semibold"
                          >
                            NPI: {match.npi}
                          </Text>
                        </Flex>
                        <Text lineHeight="shorter" fontWeight="normal">
                          {match.specialty}
                        </Text>
                        <Text lineHeight="shorter" fontWeight="normal">
                          {match.address}
                        </Text>
                      </Flex>
                    </Flex>
                  </Checkbox>
                );
              })}
            </Box>
          </CardBody>
        </Card>
      ) : (
        <ResolveForm
          refetchNpiData={(provider: ProviderUpdateInput) => {
            fetchMatches(provider);
          }}
          provider={provider}
          data={submissionData}
        />
      )}
    </>
  );
}
