import { SuggestedAction } from "localTypes";
export var providerToMidlevelRatio = {
    affectsGroup: true,
    warning: function () { return "More than 4 Non-Physician Providers per Provider"; },
    success: function () { return "4 or Fewer Non-Physician Providers per Provider"; },
    evaluate: function (submission) {
        var _a, _b;
        var midlevelsCount = ((_a = submission.midlevels) === null || _a === void 0 ? void 0 : _a.length) || 0;
        var providersCount = ((_b = submission.providers) === null || _b === void 0 ? void 0 : _b.length) || 0;
        if (providersCount / midlevelsCount < 0.25) {
            return SuggestedAction.Review;
        }
        else {
            return SuggestedAction.Quote;
        }
    },
};
