import { useState, ChangeEvent, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, ButtonGroup, Button, Center, Divider, Flex, Text } from "@chakra-ui/react";
import {
  FormError,
  ProducerForm,
  AddProviderCard,
  EntityForm,
  ProviderForm,
  MidlevelForm,
  StatusMenu,
  Subnav,
  QuoteResultsCard,
  QuoteResults,
  QuoteErrorCard,
  SubmissionEventDisplay,
} from "components";
import { useForm } from "react-hook-form";
import {
  SubmissionUpdateInput,
  ProviderUpdateInput,
  EntityUpdateInput,
  MidlevelUpdateInput,
  Event,
  Provider,
  StatusType,
} from "__generated__/graphql";
import { useModelRatingApi, useSmartyStreets } from "hooks";
import { useMutation } from "@apollo/client";
import {
  DELETE_PROVIDER,
  DELETE_MIDLEVEL,
  DELETE_ENTITY,
  UPDATE_PROVIDERS,
  UPDATE_MIDLEVELS,
  UPDATE_ENTITIES,
  UPDATE_QUOTE_PROPERTIES,
  UPDATE_PRODUCER,
  CREATE_PROVIDER,
  CREATE_MIDLEVEL,
  CREATE_ENTITY,
  RESET_QUICK_AUDIT,
} from "mutations";
import { shouldResetQuickAudit, generateId } from "utils";
import { GET_SUBMISSION } from "queries";

function checkHasUnresolvedProviders(submission?: SubmissionUpdateInput) {
  return submission?.providers?.some(
    (p) => !p.npi || !p.nppesCity || !p.nppesState || !p.nppesSpecialty,
  );
}

interface RosterProps {
  quoteResults?: QuoteResults;
  data: SubmissionUpdateInput;
  sunlightErrors?: string[];
  fetchAndSaveMrs: () => Promise<void>;
  events?: Event[];
  status?: StatusType;
}

export function Roster({
  fetchAndSaveMrs,
  events,
  sunlightErrors,
  quoteResults,
  status,
  data,
}: RosterProps) {
  const { details } = useSmartyStreets();
  const { fetchModelRating } = useModelRatingApi();
  const navigate = useNavigate();
  const [resetQuickAudit] = useMutation(RESET_QUICK_AUDIT);
  const [createMidlevel] = useMutation(CREATE_MIDLEVEL, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [createEntity] = useMutation(CREATE_ENTITY, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [createProvider] = useMutation(CREATE_PROVIDER, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [updateEntities] = useMutation(UPDATE_ENTITIES);
  const [updateMidlevels] = useMutation(UPDATE_MIDLEVELS);
  const [updateProviders] = useMutation(UPDATE_PROVIDERS);
  const [updateQuoteProperties] = useMutation(UPDATE_QUOTE_PROPERTIES);
  const [updateProducer] = useMutation(UPDATE_PRODUCER, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const { id } = useParams();
  const [deleteProvider] = useMutation(DELETE_PROVIDER);
  const [deleteMidlevel] = useMutation(DELETE_MIDLEVEL);
  const [deleteEntity] = useMutation(DELETE_ENTITY);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isContinuing, setIsContinuing] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    getValues,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm<SubmissionUpdateInput>({
    defaultValues: { ...data, providers: data?.providers || [] },
  });
  const formData = watch();
  register("providers", { required: "Please add at least one provider" });

  const midlevels = watch("midlevels");
  const entities = watch("entities");

  async function handleFormSubmissionAndRedirect(submission: SubmissionUpdateInput) {
    await onSubmit(submission);
    const hasUnresolvedProviders = checkHasUnresolvedProviders(submission);
    if (hasUnresolvedProviders) {
      navigate(`/insights/${submission.id}/resolve`);
    } else {
      navigate(`/insights/${submission.id}/model-insights`);
    }
  }
  const onSubmit = useCallback(
    async (submission: SubmissionUpdateInput) => {
      setIsSaving(true);
      let isChangingQuickAuditFields = shouldResetQuickAudit(dirtyFields);
      isChangingQuickAuditFields =
        isChangingQuickAuditFields ||
        !(
          data?.providers?.map((p) => JSON.stringify(p.address)).join("") ===
          submission?.providers?.map((p) => JSON.stringify(p.address)).join("")
        );

      try {
        if (submission.producer && submission.producer.id === undefined) {
          submission.producer.id = generateId();
        }
        if (submission.providers && submission.providers.length > 0) {
          await Promise.all([
            ...submission.providers?.map(async (p: ProviderUpdateInput) => {
              const d = await details(
                p?.address?.streetName || "",
                p?.address?.city || "",
                p?.address?.state || "",
              );
              if (d && p.address) {
                p.address.countyFips = d.countyFips;
                p.address.countyName = d.countyName;
              }

              return p;
            }),
          ]);
        }

        await Promise.all([
          updateProviders({
            variables: {
              submissionId: id,
              providers: submission.providers,
            },
          }),
          updateEntities({
            variables: {
              submissionId: id,
              entities: submission.entities,
            },
          }),
          updateMidlevels({
            variables: {
              submissionId: id,
              midlevels: submission.midlevels,
            },
          }),
          updateQuoteProperties({
            variables: {
              submissionId: id,
              quoteProperties: submission.quoteProperties,
            },
          }),
          updateProducer({
            variables: {
              submissionId: id,
              producer: submission.producer || undefined,
            },
          }),
        ]);
        if (isChangingQuickAuditFields) {
          const providers = submission.providers || [];
          await Promise.all([
            ...providers.map(async (p) => {
              await fetchModelRating({
                ...p,
                isQuickAuditEnabled: true,
              } as Provider);
            }),
          ]);
          await resetQuickAudit({
            variables: {
              submissionId: id,
            },
          });
          await fetchAndSaveMrs();
        }
      } catch (e) {
        console.error(e);
      } finally {
        setIsSaving(false);
      }
    },
    [
      fetchAndSaveMrs,
      fetchModelRating,
      data,
      dirtyFields,
      details,
      id,
      updateProducer,
      updateProviders,
      updateMidlevels,
      updateEntities,
      updateQuoteProperties,
      resetQuickAudit,
    ],
  );

  const handleSelectChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target;
      setValue(name as keyof SubmissionUpdateInput, value);
      onSubmit(getValues());
    },
    [setValue, getValues, onSubmit],
  );

  return (
    <div>
      <Subnav
        leftContent={<Text fontSize="lg">Provider Info</Text>}
        rightContent={
          <Flex alignItems="center" gap="16px">
            <SubmissionEventDisplay events={events || []} />
            <Center height="21px">
              <Divider color="gray.300" orientation="vertical" />
            </Center>
            <ButtonGroup>
              <Button
                data-cy="roster-save-button"
                style={{ width: "500px !important" }}
                variant="link"
                colorScheme="indigo"
                onClick={handleSubmit(onSubmit)}
                type="submit"
                isLoading={!isContinuing && isSaving}
                loadingText="Saving"
              >
                Save
              </Button>
              <AddProviderCard
                handleAddProvider={async () => {
                  try {
                    setIsSaving(true);
                    let updatedProviders: ProviderUpdateInput[] = [];
                    if (formData?.providers) {
                      updatedProviders = [...formData.providers];
                    }
                    const newProvider = { id: generateId() };
                    updatedProviders.push(newProvider);
                    setValue("providers", updatedProviders);
                    await createProvider({
                      variables: {
                        submissionId: id,
                        provider: newProvider,
                      },
                    });
                  } catch (e) {
                    console.error(e);
                  } finally {
                    setIsSaving(false);
                  }
                }}
                handleAddEntity={async () => {
                  try {
                    setIsSaving(true);
                    let updatedEntities: EntityUpdateInput[] = [];
                    if (entities) {
                      updatedEntities = [...entities];
                    }
                    const newEntity = { id: generateId() };
                    updatedEntities.push(newEntity);
                    setValue("entities", updatedEntities);
                    await createEntity({
                      variables: {
                        submissionId: id,
                        entity: newEntity,
                      },
                    });
                  } catch (e) {
                    console.error(e);
                  } finally {
                    setIsSaving(false);
                  }
                }}
                handleAddMidlevel={async () => {
                  try {
                    setIsSaving(true);
                    let updatedMidlevels: MidlevelUpdateInput[] = [];
                    if (midlevels) {
                      updatedMidlevels = [...midlevels];
                    }
                    const newMidlevel = { id: generateId() };
                    updatedMidlevels.push(newMidlevel);
                    setValue("midlevels", updatedMidlevels);
                    await createMidlevel({
                      variables: {
                        submissionId: id,
                        midlevel: newMidlevel,
                      },
                    });
                  } catch (e) {
                    console.error(e);
                  } finally {
                    setIsSaving(false);
                  }
                }}
              />
              <StatusMenu status={data?.status || undefined} />
              <Button
                data-cy="roster-continue-button"
                w="97px"
                isLoading={isContinuing && isSaving}
                colorScheme="indigo"
                onClick={() => {
                  setIsContinuing(true);
                  handleSubmit(handleFormSubmissionAndRedirect)();
                }}
              >
                Continue
              </Button>
            </ButtonGroup>
          </Flex>
        }
      />
      <Flex w="100%" direction="column" alignItems="center" pr="47px" pt="50px">
        <QuoteErrorCard sunlightErrors={sunlightErrors || []} />
        <Box
          maxW={{ lg: "1040px", md: "600px", sm: "400px" }}
          minW={{ lg: "940px", md: "600px", sm: "400px" }}
          m="30px 30px 30px 30px"
          pl="10px"
          pr="15px"
        >
          {quoteResults && <QuoteResultsCard quoteResults={quoteResults} />}
          <Box w="100%">
            <form onSubmit={handleSubmit(onSubmit)}>
              {Object.keys(errors)?.length > 0 && (
                <FormError
                  message={
                    errors?.providers ? errors.providers?.message : "Please correct form errors"
                  }
                />
              )}
              <ProducerForm
                isRatSubmission={status === StatusType.Ratwip || status === StatusType.RatOfficial}
                clearErrors={clearErrors}
                control={control}
                setValue={setValue}
                producer={data?.producer}
                onChangeSelect={handleSelectChange}
                register={register}
                errors={errors}
              />
              {formData?.entities?.map((entity: EntityUpdateInput, index: number) => {
                const address = watch(`entities.${index}.address`);
                return (
                  <EntityForm
                    clearErrors={clearErrors}
                    onChangeSelect={handleSelectChange}
                    deleteEntity={() => {
                      let updatedEntities: EntityUpdateInput[] = [];
                      if (formData?.entities) {
                        updatedEntities = [...formData.entities.filter((e) => e.id !== entity.id)];
                      }
                      setValue("entities", updatedEntities);
                      deleteEntity({
                        variables: {
                          input: {
                            submissionId: data.id,
                            entityId: entity.id,
                          },
                        },
                      });
                    }}
                    control={control}
                    key={entity.id}
                    lengthOfSet={formData?.entities?.length || 0}
                    index={index}
                    address={address}
                    setValue={setValue}
                    register={register}
                    entity={entity}
                    errors={errors}
                  />
                );
              })}
              <div>
                {formData?.providers?.map((provider: ProviderUpdateInput, index: number) => {
                  const address = watch(`providers.${index}.address`);
                  return (
                    <ProviderForm
                      clearErrors={clearErrors}
                      provider={provider}
                      duplicateProvider={() => {
                        let updatedProviders: ProviderUpdateInput[] = [];
                        const newProvider = {
                          ...formData?.providers?.find((p) => p.id === provider.id),
                          id: generateId(),
                          npi: "",
                          mrs: null,
                          nppesCity: "",
                          nppesState: "",
                          nppesSpecialty: "",
                          scheduledRatingFactor: 0,
                          manualIndigoSpecialty: provider.indigoSpecialty,
                          manualPreviewPremium: null,
                          manualMrs: null,
                        };
                        if (formData?.providers) {
                          updatedProviders = [...formData?.providers, newProvider];
                        }
                        setValue("providers", updatedProviders);
                        createProvider({
                          variables: {
                            submissionId: id,
                            provider: newProvider,
                          },
                        });
                      }}
                      deleteProvider={() => {
                        let updatedProviders: ProviderUpdateInput[] = [];
                        if (formData?.providers) {
                          updatedProviders = [
                            ...formData.providers.filter((p) => p.id !== provider.id),
                          ];
                        }
                        setValue("providers", updatedProviders);
                        deleteProvider({
                          variables: {
                            input: {
                              submissionId: data.id,
                              providerId: provider.id,
                            },
                          },
                        });
                      }}
                      onChangeSelect={handleSelectChange}
                      control={control}
                      setValue={setValue}
                      address={address}
                      errors={errors}
                      key={provider.id}
                      index={index}
                      register={register}
                      lengthOfSet={formData?.providers?.length || 0}
                    />
                  );
                })}
              </div>
              <div>
                {formData?.midlevels?.map((midlevel: MidlevelUpdateInput, index: number) => {
                  const address = watch(`midlevels.${index}.address`);
                  return (
                    <MidlevelForm
                      clearErrors={clearErrors}
                      midlevel={midlevel}
                      duplicateMidlevel={() => {
                        let updatedMidlevels: MidlevelUpdateInput[] = [];
                        const newMidlevel = {
                          ...formData?.midlevels?.find((m) => m.id === midlevel.id),
                          id: generateId(),
                        };
                        if (formData?.midlevels) {
                          updatedMidlevels = [...formData.midlevels, newMidlevel];
                        }
                        setValue("midlevels", updatedMidlevels);
                        createMidlevel({
                          variables: {
                            submissionId: id,
                            midlevel: newMidlevel,
                          },
                        });
                      }}
                      deleteMidlevel={() => {
                        let updatedMidlevels: MidlevelUpdateInput[] = [];
                        if (formData?.midlevels) {
                          updatedMidlevels = [
                            ...formData.midlevels.filter((m) => m.id !== midlevel.id),
                          ];
                        }
                        setValue("midlevels", updatedMidlevels);
                        deleteMidlevel({
                          variables: {
                            input: {
                              submissionId: data.id,
                              midlevelId: midlevel.id,
                            },
                          },
                        });
                      }}
                      control={control}
                      onChangeSelect={handleSelectChange}
                      setValue={setValue}
                      address={address}
                      errors={errors}
                      key={midlevel.id}
                      index={index}
                      register={register}
                      lengthOfSet={formData?.midlevels?.length || 0}
                    />
                  );
                })}
              </div>
            </form>
          </Box>
        </Box>
      </Flex>
    </div>
  );
}
