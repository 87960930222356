var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useContext, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ApiClientContext } from "providers";
import { INDIGO_SPECIALTIES, DEFAULT_MRS_VALUE } from "app-constants";
import { useMutation } from "@apollo/client";
import { UPDATE_SUBMISSION, UPDATE_PROVIDERS, UPDATE_MIDLEVELS } from "mutations";
import { sumPremiums, formatCustomerPremiums } from "utils";
import { GET_SUBMISSION } from "queries";
export var useFetchPricingData = function () {
    var updateProviders = useMutation(UPDATE_PROVIDERS)[0];
    var updateMidlevels = useMutation(UPDATE_MIDLEVELS)[0];
    var updateSubmission = useMutation(UPDATE_SUBMISSION, {
        refetchQueries: [GET_SUBMISSION],
        awaitRefetchQueries: true,
    })[0];
    var id = useParams().id;
    var _a = useState([]), pricingErrors = _a[0], setPricingErrors = _a[1];
    var _b = useState(false), isFetchingPricingData = _b[0], setIsFetchingPricingData = _b[1];
    var apiClient = useContext(ApiClientContext);
    var fetchPricingData = useCallback(function (submission, averageMrs) { return __awaiter(void 0, void 0, void 0, function () {
        var submittedProviders, submittedMidlevels, pricingData_1, validPreviewPremium_1, validPreviewPremiumsAreStale, e_1;
        var _a;
        var _b, _c, _d, _e, _f;
        return __generator(this, function (_g) {
            switch (_g.label) {
                case 0:
                    _g.trys.push([0, 7, 8, 9]);
                    setPricingErrors([]);
                    setIsFetchingPricingData(true);
                    if (!submission.providers) return [3 /*break*/, 6];
                    submittedProviders = (_b = submission.providers) === null || _b === void 0 ? void 0 : _b.map(function (provider) {
                        var _a, _b;
                        var specialtyId;
                        var validSpecialty = submission.isQuickAuditEnabled
                            ? provider.indigoSpecialty
                            : provider.manualIndigoSpecialty;
                        if (validSpecialty) {
                            specialtyId = (_a = INDIGO_SPECIALTIES.find(function (specialty) { return specialty.name === validSpecialty; })) === null || _a === void 0 ? void 0 : _a.value;
                        }
                        else if (provider.indigoSpecialty) {
                            specialtyId = (_b = INDIGO_SPECIALTIES.find(function (specialty) { return specialty.name === provider.indigoSpecialty; })) === null || _b === void 0 ? void 0 : _b.value;
                        }
                        return __assign(__assign({}, provider), { indigoSpecialty: specialtyId, specialty: provider.specialty || validSpecialty, newToPractice: undefined, partTimePractice: provider.partTimePractice === "3102"
                                ? (averageMrs || 0) > 0.52
                                    ? "3102"
                                    : undefined
                                : undefined, mrs: provider.mrs || DEFAULT_MRS_VALUE });
                    });
                    submittedMidlevels = (_c = submission.midlevels) === null || _c === void 0 ? void 0 : _c.map(function (midlevel) {
                        return __assign(__assign({}, midlevel), { partTimePractice: midlevel.partTimePractice === "3102"
                                ? (averageMrs || 0) > 0.52
                                    ? "3102"
                                    : undefined
                                : undefined });
                    });
                    return [4 /*yield*/, (apiClient === null || apiClient === void 0 ? void 0 : apiClient.calculateSunlightPremium(__assign(__assign({}, submission), { providers: submittedProviders, midlevels: submittedMidlevels || [], 
                            // Hardcode Indigo internal producer and agency IDs for price-check
                            producer: __assign(__assign({}, submission.producer), { producerId: "PR39775", agency: __assign(__assign({}, (_d = submission === null || submission === void 0 ? void 0 : submission.producer) === null || _d === void 0 ? void 0 : _d.agency), { agencyId: "BR57958" }) }), srfAdjustment: submission.useCalculatedSrfAdjustment
                                ? submission.calculatedSrfAdjustment
                                : submission.srfAdjustment })))];
                case 1:
                    pricingData_1 = _g.sent();
                    if (!pricingData_1) return [3 /*break*/, 6];
                    if (((_e = pricingData_1.quoteResults[0]) === null || _e === void 0 ? void 0 : _e.ListOfErrors) && submission.status !== "Declined") {
                        setPricingErrors(pricingData_1.quoteResults[0].ListOfErrors);
                    }
                    validPreviewPremium_1 = submission.isQuickAuditEnabled !== false ? "previewPremium" : "manualPreviewPremium";
                    validPreviewPremiumsAreStale = submission.isQuickAuditEnabled !== false
                        ? "previewPremiumsAreStale"
                        : "manualPreviewPremiumsAreStale";
                    return [4 /*yield*/, updateProviders({
                            variables: {
                                submissionId: id,
                                providers: submission.providers.map(function (provider) {
                                    var _a;
                                    var _b;
                                    return __assign(__assign({}, provider), (_a = {}, _a[validPreviewPremium_1] = (_b = formatCustomerPremiums(pricingData_1).find(function (p) { return p.npi === provider.npi; })) === null || _b === void 0 ? void 0 : _b.premium, _a));
                                }),
                            },
                        })];
                case 2:
                    _g.sent();
                    if (!(Array.isArray(submission.midlevels) && submission.midlevels.length > 0)) return [3 /*break*/, 4];
                    return [4 /*yield*/, updateMidlevels({
                            variables: {
                                submissionId: id,
                                midlevels: (_f = submission === null || submission === void 0 ? void 0 : submission.midlevels) === null || _f === void 0 ? void 0 : _f.map(function (midlevel) {
                                    var _a;
                                    var _b;
                                    return __assign(__assign({}, midlevel), (_a = {}, _a[validPreviewPremium_1] = (_b = formatCustomerPremiums(pricingData_1).find(function (p) {
                                        return "".concat(p.firstName, " ").concat(p.lastName) ===
                                            "".concat(midlevel.firstName, " ").concat(midlevel.lastName);
                                    })) === null || _b === void 0 ? void 0 : _b.premium, _a));
                                }),
                            },
                        })];
                case 3:
                    _g.sent();
                    _g.label = 4;
                case 4: return [4 /*yield*/, updateSubmission({
                        variables: {
                            input: (_a = {
                                    id: id
                                },
                                _a[validPreviewPremium_1] = sumPremiums(pricingData_1 === null || pricingData_1 === void 0 ? void 0 : pricingData_1.quoteResults),
                                _a[validPreviewPremiumsAreStale] = false,
                                _a),
                        },
                    })];
                case 5:
                    _g.sent();
                    _g.label = 6;
                case 6: return [3 /*break*/, 9];
                case 7:
                    e_1 = _g.sent();
                    console.error(e_1);
                    return [3 /*break*/, 9];
                case 8:
                    setIsFetchingPricingData(false);
                    return [7 /*endfinally*/];
                case 9: return [2 /*return*/];
            }
        });
    }); }, [apiClient, id, updateProviders, updateSubmission, updateMidlevels]);
    return { fetchPricingData: fetchPricingData, isFetchingPricingData: isFetchingPricingData, pricingErrors: pricingErrors };
};
