import { SuggestedAction } from "localTypes";
var BOARD_ACTION_STATES = ["IL", "TX", "MO", "FL", "GA", "CA", "NJ", "NY"];
export var boardActions = {
    evaluate: function (provider) {
        var _a, _b;
        if ((provider === null || provider === void 0 ? void 0 : provider.boardActions) && ((_a = provider === null || provider === void 0 ? void 0 : provider.boardActions) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            return SuggestedAction.Review;
        }
        else if (BOARD_ACTION_STATES.includes(((_b = provider === null || provider === void 0 ? void 0 : provider.address) === null || _b === void 0 ? void 0 : _b.state) || "")) {
            return SuggestedAction.Quote;
        }
        return SuggestedAction.Review;
    },
    success: function () { return "No Board Actions"; },
    warning: function () { return "Check Board Actions"; },
    error: function () { return "Board actions unknown"; },
    affectsGroup: true,
};
