import { SuggestedAction } from "localTypes";
export var inAdjustedMeans = {
    evaluate: function (provider) {
        if (!(provider === null || provider === void 0 ? void 0 : provider.excludedNpi)) {
            return SuggestedAction.Quote;
        }
        return SuggestedAction.Review;
    },
    success: function () { return "In Adjusted Means"; },
    warning: function () { return "Not In Adjusted Means"; },
    affectsGroup: true,
};
