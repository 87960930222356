import { SuggestedAction } from "localTypes";
export var webSearchInProgress = {
    evaluate: function (provider) {
        if (provider.redFlagSearchCompleted) {
            return SuggestedAction.Quote;
        }
        return SuggestedAction.Review;
    },
    success: function () { return "Red Flag Search Complete"; },
    warning: function () { return "Searching Red Flags"; },
    affectsGroup: false,
};
