var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __esDecorate = (this && this.__esDecorate) || function (ctor, descriptorIn, decorators, contextIn, initializers, extraInitializers) {
    function accept(f) { if (f !== void 0 && typeof f !== "function") throw new TypeError("Function expected"); return f; }
    var kind = contextIn.kind, key = kind === "getter" ? "get" : kind === "setter" ? "set" : "value";
    var target = !descriptorIn && ctor ? contextIn["static"] ? ctor : ctor.prototype : null;
    var descriptor = descriptorIn || (target ? Object.getOwnPropertyDescriptor(target, contextIn.name) : {});
    var _, done = false;
    for (var i = decorators.length - 1; i >= 0; i--) {
        var context = {};
        for (var p in contextIn) context[p] = p === "access" ? {} : contextIn[p];
        for (var p in contextIn.access) context.access[p] = contextIn.access[p];
        context.addInitializer = function (f) { if (done) throw new TypeError("Cannot add initializers after decoration has completed"); extraInitializers.push(accept(f || null)); };
        var result = (0, decorators[i])(kind === "accessor" ? { get: descriptor.get, set: descriptor.set } : descriptor[key], context);
        if (kind === "accessor") {
            if (result === void 0) continue;
            if (result === null || typeof result !== "object") throw new TypeError("Object expected");
            if (_ = accept(result.get)) descriptor.get = _;
            if (_ = accept(result.set)) descriptor.set = _;
            if (_ = accept(result.init)) initializers.unshift(_);
        }
        else if (_ = accept(result)) {
            if (kind === "field") initializers.unshift(_);
            else descriptor[key] = _;
        }
    }
    if (target) Object.defineProperty(target, contextIn.name, descriptor);
    done = true;
};
var __runInitializers = (this && this.__runInitializers) || function (thisArg, initializers, value) {
    var useValue = arguments.length > 2;
    for (var i = 0; i < initializers.length; i++) {
        value = useValue ? initializers[i].call(thisArg, value) : initializers[i].call(thisArg);
    }
    return useValue ? value : void 0;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __setFunctionName = (this && this.__setFunctionName) || function (f, name, prefix) {
    if (typeof name === "symbol") name = name.description ? "[".concat(name.description, "]") : "";
    return Object.defineProperty(f, "name", { configurable: true, value: prefix ? "".concat(prefix, " ", name) : name });
};
import "reflect-metadata";
import { singleton } from "tsyringe";
import { BaseApiClient } from "./base_api_client";
var ApiClient = function () {
    var _classDecorators = [singleton()];
    var _classDescriptor;
    var _classExtraInitializers = [];
    var _classThis;
    var _classSuper = BaseApiClient;
    var ApiClient = _classThis = /** @class */ (function (_super) {
        __extends(ApiClient_1, _super);
        function ApiClient_1(env, region, httpClient, credentialProvider) {
            return _super.call(this, ApiClient.determineApiBaseUrl(env), region, httpClient, credentialProvider) || this;
        }
        ApiClient_1.determineApiBaseUrl = function (env) {
            if (process.env.REACT_APP_IS_LOCAL) {
                return "http://localhost:3500";
            }
            else if (process.env.IS_LOCAL_CONTAINER) {
                return "http://host.docker.internal:3500";
            }
            else if (this.ENV_TO_API_URLS.has(env)) {
                return this.ENV_TO_API_URLS.get(env);
            }
            else {
                console.error("No API URL found for environment ".concat(env, "! Falling back to dev URL."));
                return this.ENV_TO_API_URLS.get("dev");
            }
        };
        ApiClient_1.prototype.npiSearch = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/npiSearch", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_1 = _a.sent();
                            this.logError("Error fetching NPI data", e_1);
                            throw e_1;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.modelRating = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_2;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/modelRating", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_2 = _a.sent();
                            this.logError("Error fetching model rating data", e_2);
                            throw e_2;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.queueProcessDocumentRequest = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/processDocument", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_3 = _a.sent();
                            this.logError("Error queuing document processing", e_3);
                            throw e_3;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.upsertSalesforceLead = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_4;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/salesforceIntegration", request))];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            e_4 = _a.sent();
                            this.logError("Error upserting Salesforce lead", e_4);
                            throw e_4;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.processEmailAttachments = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_5;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/zapierIntegration", request))];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            e_5 = _a.sent();
                            this.logError("Error processing email attachments", e_5);
                            throw e_5;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.getSunlightAgents = function () {
            return __awaiter(this, void 0, Promise, function () {
                var e_6;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("GET", "/sunlightIntegration/agents"))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_6 = _a.sent();
                            this.logError("Error fetching Sunlight agents", e_6);
                            throw e_6;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.previewQuoteDocument = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_7;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/quote/previewDocument", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_7 = _a.sent();
                            this.logError("Error previewing quote document", e_7);
                            throw e_7;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.sendQuoteDocument = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_8;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/quote/afterQuote", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_8 = _a.sent();
                            this.logError("Error previewing quote document", e_8);
                            throw e_8;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.calculateSunlightPremium = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_9;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/sunlightIntegration/calculatePremium", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_9 = _a.sent();
                            this.logError("Error submitting Sunlight calculatePremium", e_9);
                            throw e_9;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.getSubmissionDocumentUrls = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var _req, e_10;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            _req = { submissionId: request.id };
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/submissionDocuments", _req))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_10 = _a.sent();
                            this.logError("Error fetching submission documents", e_10);
                            throw e_10;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.getSubmissionDocumentPostUrls = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_11;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/submissionDocuments", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_11 = _a.sent();
                            this.logError("Error fetching submission documents", e_11);
                            throw e_11;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.riskColor = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_12;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/riskColor", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_12 = _a.sent();
                            this.logError("Error fetching risk color", e_12);
                            throw e_12;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.lambdaTester = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_13;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/lambdaTester", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_13 = _a.sent();
                            this.logError("Error fetching lambdaTester", e_13);
                            throw e_13;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.emitEvent = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_14;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/eventEmitter", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_14 = _a.sent();
                            this.logError("Error emitting event", e_14);
                            throw e_14;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.sunlightSalesforceIntegrationWebhook = function (event) {
            return __awaiter(this, void 0, Promise, function () {
                var e_15;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/sunlightSalesforceIntegration", event))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_15 = _a.sent();
                            this.logError("Error emitting event", e_15);
                            throw e_15;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.getRedFlagSearch = function (submissionId, id) {
            return __awaiter(this, void 0, Promise, function () {
                var e_16;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("GET", "/ai/redFlagSearch/submission/".concat(submissionId, "/id/").concat(id)))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_16 = _a.sent();
                            this.logError("Error fetching red flag search", e_16);
                            throw e_16;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        ApiClient_1.prototype.postRedFlagSearch = function (request) {
            return __awaiter(this, void 0, Promise, function () {
                var e_17;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this._sendRequest(this.constructHttpRequest("POST", "/ai/redFlagSearch", request))];
                        case 1: return [2 /*return*/, _a.sent()];
                        case 2:
                            e_17 = _a.sent();
                            this.logError("Error posting red flag search", e_17);
                            throw e_17;
                        case 3: return [2 /*return*/];
                    }
                });
            });
        };
        return ApiClient_1;
    }(_classSuper));
    __setFunctionName(_classThis, "ApiClient");
    (function () {
        var _a;
        var _metadata = typeof Symbol === "function" && Symbol.metadata ? Object.create((_a = _classSuper[Symbol.metadata]) !== null && _a !== void 0 ? _a : null) : void 0;
        __esDecorate(null, _classDescriptor = { value: _classThis }, _classDecorators, { kind: "class", name: _classThis.name, metadata: _metadata }, null, _classExtraInitializers);
        ApiClient = _classThis = _classDescriptor.value;
        if (_metadata) Object.defineProperty(_classThis, Symbol.metadata, { enumerable: true, configurable: true, writable: true, value: _metadata });
    })();
    _classThis.ENV_TO_API_URLS = new Map([
        ["dev", "https://dev.api.getindigo.com"],
        ["uat", "https://uat.api.getindigo.com"],
        ["main", "https://main.api.getindigo.com"],
        ["yotam", "https://yotam.api.getindigo.com"],
        ["acorley", "https://acorley.api.getindigo.com"],
    ]);
    (function () {
        __runInitializers(_classThis, _classExtraInitializers);
    })();
    return ApiClient = _classThis;
}();
export { ApiClient };
