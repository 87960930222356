import { useEffect, useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useParams } from "react-router-dom";
import { ApiClientContext } from "providers";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useQuery, useMutation } from "@apollo/client";
import { ModelInsights } from "./model-insights";
import { Resolve } from "./resolve";
import { Roster } from "./roster";
import { StalePricingProvider, AppetiteProvider } from "providers";
import { Navbar, SidePanel, QuoteResults, Loader, BugButton, QuickAuditToggle } from "components";
import { Box } from "@chakra-ui/react";
import {
  ProviderUpdateInput,
  Provider,
  NoteInput,
  EventState,
  Event,
  EventType,
  NoteType,
  Note,
  InsightsData,
} from "__generated__/graphql";
import { GET_SUBMISSION, GET_LAST_QUOTE_CREATION_EVENTS } from "queries";
import { generateId, formatAsDollar, formatBrokerNote } from "utils";
import { CALCULATE_INSIGHTS_DATA, UPDATE_SUBMISSION, CREATE_NOTE } from "mutations";
import {
  useLocalAuthSession,
  useDocumentTitle,
  useModelRatingApi,
  useFetchPricingData,
} from "hooks";
import { fetchUserAttributes } from "aws-amplify/auth";
import { Defaults } from "@app-stack/types/sunlight";

export function Insights() {
  useDocumentTitle("LUX");
  const { fetchModelRating } = useModelRatingApi();
  const defaultInsightsData: InsightsData = {
    averageAdjustedMrs: 0,
    averageMrs: 0,
    bookedSrf: 0,
    calculatedSrfAdjustment: 0,
    providers: [],
    scheduledRatingFactor: 0,
  };
  const { fetchPricingData, isFetchingPricingData, pricingErrors } = useFetchPricingData();
  const { id } = useParams();
  const apiClient = useContext(ApiClientContext);
  const [sunlightErrors, setSunlightErrors] = useState<string[]>([]);
  const [quoteResults, setQuoteResults] = useState<QuoteResults | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingQuickAuditToggle, setIsLoadingQuickAuditToggle] = useState(false);
  const currentUser = useLocalAuthSession();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [localInsightsData, setLocalInsightsData] = useState<InsightsData>(defaultInsightsData);
  const [createNote] = useMutation(CREATE_NOTE, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [calculateInsightsData, { data: insightsDataRaw, loading: insightsDataIsLoading }] =
    useMutation(CALCULATE_INSIGHTS_DATA, {
      refetchQueries: [GET_SUBMISSION],
      awaitRefetchQueries: true,
    });
  const insightsData = insightsDataRaw?.calculateInsightsData;

  useEffect(() => {
    if (pricingErrors.length > 0) {
      setSunlightErrors(pricingErrors);
    }
  }, [pricingErrors]);

  useEffect(() => {
    if (
      insightsData !== undefined &&
      insightsData !== null &&
      insightsData?.averageAdjustedMrs !== localInsightsData?.averageAdjustedMrs
    ) {
      setLocalInsightsData(insightsData);
    }
  }, [insightsData?.averageMrs, localInsightsData, insightsData]);

  useEffect(() => {
    if (insightsData === undefined && insightsDataIsLoading === false) {
      calculateInsightsData({
        variables: {
          submissionId: id,
        },
      });
    }
  }, [id, calculateInsightsData, insightsData, insightsDataIsLoading]);

  const [updateSubmission] = useMutation(UPDATE_SUBMISSION, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });

  const { loading, data, refetch } = useQuery(GET_SUBMISSION, {
    variables: { id },
  });
  const brokerEmail = data?.getSubmission?.producer?.email;
  const {
    loading: loadingEventData,
    data: eventData,
    startPolling,
    stopPolling,
    refetch: refetchEventData,
  } = useQuery(GET_LAST_QUOTE_CREATION_EVENTS, {
    variables: { submissionId: id },
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (data?.getSubmission) {
      const sub = data?.getSubmission;
      if (sub?.quoteProperties) {
        setQuoteResults({
          isDeclined: sub.status === "Declined",
          simplifyQuoteId: sub.quoteProperties?.simplifyQuoteId,
          initialPremium: sub.quoteProperties?.initialPremium,
          primaryCustomerId: sub.quoteProperties?.primaryCustomerId,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    let errors = [];
    if (
      eventData?.getLastQuoteCreationEvents?.find(
        (event: Event) =>
          event.state !== EventState.Queued && event.state !== EventState.Processing,
      ) &&
      eventData?.getLastQuoteCreationEvents.find(
        (event: Event) => event.state === EventState.Failed,
      )
    ) {
      const failedEvents = eventData?.getLastQuoteCreationEvents.filter(
        (event: Event) => event.state === EventState.Failed,
      );
      const hasFailedCustomers = !!failedEvents.find(
        (event: Event) => event.type === EventType.CreateSlCustomer,
      );
      const hasFailedOnQuoteCreation = !!failedEvents.find(
        (event: Event) => event.type === EventType.CreateSlQuote,
      );
      const hasFailedOnOrchestration = !!failedEvents.find(
        (event: Event) => event.type === EventType.CreateQuoteOrchestration,
      );
      if (hasFailedCustomers) {
        errors.push("Failed to create customers in Sunlight");
      }
      if (hasFailedOnQuoteCreation) {
        errors.push("Failed to create quote.");
      }
      if (hasFailedOnOrchestration) {
        errors.push("Failed to orchestrate quote creation.");
      }
      if (data?.getSubmission?.status !== "Declined") {
        setSunlightErrors(errors);
      }
    }
  }, [eventData, data?.getSubmission?.status]);

  useEffect(() => {
    if (
      eventData?.getLastQuoteCreationEvents?.find(
        (event: Event) => event.state !== EventState.Complete && event.state !== EventState.Failed,
      )
    ) {
      startPolling(2000);
    } else {
      refetch();
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [refetch, eventData, startPolling, stopPolling]);

  async function previewQuoteDocument(
    submissionId: string,
    policyExclusions: string,
  ): Promise<{ documentPreviewUrl: string } | undefined> {
    try {
      if (submissionId) {
        return await apiClient?.previewQuoteDocument({
          submissionId,
          policyExclusions,
        });
      } else {
        throw new Error("Submission ID is missing");
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function submitToSunlight() {
    try {
      setIsLoading(true);
      setSunlightErrors([]);
      const attributes = await fetchUserAttributes();
      if (id) {
        await apiClient?.emitEvent({
          submissionId: id,
          createdBy: "Frontend",
          type: EventType.CreateQuoteOrchestration,
          retriesRemaining: 0,
          payload: { sunlightUserId: attributes?.["custom:sunlight_user_id"] ?? Defaults.USER_ID },
        });
        await refetchEventData();
        startPolling(2000);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleAddNote(note: NoteInput) {
    await createNote({
      variables: {
        submissionId: id,
        note: { ...note, id: generateId() },
      },
    });
  }

  const subjectivities = (data?.getSubmission?.underwriterNotes || []).filter(
    (note: Note) => note.type === NoteType.Subjectivity,
  );

  async function fetchAndSaveMrs() {
    try {
      if (data?.getSubmission?.providers) {
        await Promise.all(
          data?.getSubmission?.providers?.map(async (provider: ProviderUpdateInput) => {
            await fetchModelRating(provider as Provider);
          }),
        );
        calculateInsightsData({
          variables: {
            submissionId: id,
          },
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  const validPremium =
    data?.getSubmission?.isQuickAuditEnabled !== false ? "previewPremium" : "manualPreviewPremium";
  return (
    <AppetiteProvider submission={data?.getSubmission || null}>
      <StalePricingProvider
        stalePricing={
          data?.getSubmission?.isQuickAuditEnabled
            ? data?.getSubmission?.previewPremium && !!data?.getSubmission?.previewPremiumsAreStale
            : data?.getSubmission?.manualPreviewPremium &&
              !!data?.getSubmission?.manualPreviewPremiumsAreStale
        }
      >
        <div style={{ height: "100%", paddingBottom: "30px" }}>
          <Navbar userEmail={currentUser?.email} signOut={signOut} />
          <SidePanel
            author={currentUser?.email}
            notes={data?.getSubmission?.underwriterNotes || []}
            brokerNotes={formatBrokerNote(data?.getSubmission)}
            handleAddNote={handleAddNote}
          />
          <Box pt="60px">
            <Routes>
              <Route path="/">
                <Route
                  path="/model-insights/*"
                  element={
                    <ModelInsights
                      quickAuditToggle={
                        <QuickAuditToggle
                          handleToggle={async () => {
                            try {
                              setIsLoadingQuickAuditToggle(true);
                              await updateSubmission({
                                variables: {
                                  input: {
                                    id,
                                    isQuickAuditEnabled: !(
                                      data?.getSubmission?.isQuickAuditEnabled ?? true
                                    ),
                                  },
                                },
                              });
                              await fetchAndSaveMrs();
                              await calculateInsightsData({
                                variables: {
                                  submissionId: id,
                                },
                              });
                            } catch (e) {
                              console.error(e);
                            } finally {
                              setIsLoadingQuickAuditToggle(false);
                            }
                          }}
                          isDisabled={data?.getSubmission?.isQuickAuditEnabled === null}
                          isChecked={data?.getSubmission?.isQuickAuditEnabled ?? true}
                        />
                      }
                      isQuickAuditEnabled={data?.getSubmission?.isQuickAuditEnabled ?? true}
                      fetchAndSaveMrs={fetchAndSaveMrs}
                      insightsData={localInsightsData}
                      previewQuoteDocument={previewQuoteDocument}
                      brokerEmail={brokerEmail}
                      subjectivities={subjectivities}
                      declination={data?.getSubmission?.declination}
                      quoteProperties={data?.getSubmission?.quoteProperties}
                      isLoadingQuickAuditToggle={isLoadingQuickAuditToggle}
                      loading={loading}
                      targetPremium={formatAsDollar.format(
                        data?.getSubmission?.quoteProperties?.targetPremium,
                      )}
                      indigoPremium={
                        data?.getSubmission?.[validPremium] &&
                        formatAsDollar.format(data?.getSubmission?.[validPremium])
                      }
                      fetchPricingData={() => {
                        fetchPricingData(data?.getSubmission, insightsData?.averageMrs);
                      }}
                      isFetchingPricingData={isFetchingPricingData}
                      events={eventData?.getLastQuoteCreationEvents}
                      isLoadingEvents={loadingEventData}
                      modelId={data?.getSubmission?.quoteProperties?.modelInfo?.modelId}
                      quoteResults={quoteResults}
                      sunlightErrors={sunlightErrors}
                      isDisabled={
                        eventData?.getLastQuoteCreationEvents?.find(
                          (event: Event) =>
                            event.state === EventState.Processing ||
                            event.state === EventState.Queued,
                        ) !== undefined || !!quoteResults?.simplifyQuoteId
                      }
                      isSubmitting={isLoading}
                      submitToSunlight={submitToSunlight}
                      status={data?.getSubmission?.status}
                      srfAdjustment={data?.getSubmission?.srfAdjustment}
                      calculatedSrfAdjustment={data?.getSubmission?.calculatedSrfAdjustment}
                      useCalculatedSrfAdjustment={data?.getSubmission?.useCalculatedSrfAdjustment}
                      entity={data?.getSubmission?.entities?.[0]}
                      midlevels={data?.getSubmission?.midlevels || []}
                      providers={data?.getSubmission?.providers || []}
                    />
                  }
                />
                <Route
                  path="/resolve/*"
                  element={
                    <Resolve
                      fetchAndSaveMrs={fetchAndSaveMrs}
                      loading={loading}
                      events={eventData?.getLastQuoteCreationEvents}
                      sunlightErrors={sunlightErrors}
                      quoteResults={quoteResults}
                      status={data?.getSubmission?.status}
                    />
                  }
                />
                <Route
                  index
                  element={
                    loading || data?.getSubmission === undefined ? (
                      <Loader />
                    ) : (
                      <Roster
                        status={data?.getSubmission?.status}
                        fetchAndSaveMrs={fetchAndSaveMrs}
                        events={eventData?.getLastQuoteCreationEvents}
                        sunlightErrors={sunlightErrors}
                        quoteResults={quoteResults}
                        data={data?.getSubmission}
                      />
                    )
                  }
                />
              </Route>
            </Routes>
          </Box>
        </div>
        <BugButton submissionSnapshot={data?.getSubmission} />
      </StalePricingProvider>
    </AppetiteProvider>
  );
}
