import { SuggestedAction } from "localTypes";
export var inconsistencies = {
    evaluate: function (provider) {
        var _a, _b;
        var location = ((_a = provider === null || provider === void 0 ? void 0 : provider.inconsistencies) === null || _a === void 0 ? void 0 : _a.location) || [];
        var specialty = ((_b = provider === null || provider === void 0 ? void 0 : provider.inconsistencies) === null || _b === void 0 ? void 0 : _b.specialty) || [];
        if (location.some(function (loc) { return (loc === null || loc === void 0 ? void 0 : loc.isConsistent) === false; }) ||
            specialty.some(function (spec) { return (spec === null || spec === void 0 ? void 0 : spec.isConsistent) === false; })) {
            return SuggestedAction.Review;
        }
        return SuggestedAction.Quote;
    },
    success: function () { return "No Inconsistencies"; },
    warning: function () { return "Check Inconsistencies"; },
    affectsGroup: true,
};
