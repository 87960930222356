import { SuggestedAction } from "localTypes";
import { DEFAULT_MRS_VALUE, AVERAGE_MRS_QUOTE_THRESHOLD, AVERAGE_MRS_QUOTE_THRESHOLD_LARGE, DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE, } from "app-constants";
export var groupMrsThreshold = {
    affectsGroup: true,
    error: function () { return "Does Not Meet Group Threshold"; },
    warning: function () { return "Does Not Meet Group Threshold"; },
    success: function () { return "Meets Group Threshold"; },
    evaluate: function (submission) {
        var _a, _b, _c, _d, _e;
        if (((_a = submission.providers) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            return SuggestedAction.Decline;
        }
        var isQuickAuditEnabled = (_b = submission.isQuickAuditEnabled) !== null && _b !== void 0 ? _b : true;
        if (!submission.providers) {
            return SuggestedAction.Review;
        }
        var averageMrs = ((_c = submission === null || submission === void 0 ? void 0 : submission.providers) === null || _c === void 0 ? void 0 : _c.map(function (_a) {
            var ignoreLocationInconsistency = _a.ignoreLocationInconsistency, mrs = _a.mrs, manualMrs = _a.manualMrs;
            var validMrs = (isQuickAuditEnabled !== null && isQuickAuditEnabled !== void 0 ? isQuickAuditEnabled : true)
                ? mrs
                : (ignoreLocationInconsistency !== null && ignoreLocationInconsistency !== void 0 ? ignoreLocationInconsistency : true)
                    ? manualMrs || mrs
                    : DEFAULT_INCONSISTENT_LOCATION_MRS_VALUE;
            return validMrs || validMrs === 0 ? validMrs : DEFAULT_MRS_VALUE;
        }).reduce(function (a, b) { return a + b; }, 0)) / (((_d = submission === null || submission === void 0 ? void 0 : submission.providers) === null || _d === void 0 ? void 0 : _d.length) || 1) || 1;
        if ((((_e = submission === null || submission === void 0 ? void 0 : submission.providers) === null || _e === void 0 ? void 0 : _e.length) || 0) > 1) {
            if (averageMrs >= AVERAGE_MRS_QUOTE_THRESHOLD_LARGE) {
                return SuggestedAction.Decline;
            }
        }
        else if (averageMrs >= AVERAGE_MRS_QUOTE_THRESHOLD) {
            return SuggestedAction.Decline;
        }
        return SuggestedAction.Quote;
    },
};
