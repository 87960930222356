export var STATE_LICENSE_URLS = {
    AL: [
        {
            name: "Alabama Board",
            url: "https://abme.igovsolution.net/online/Lookups/Individual_Lookup.aspx",
        },
    ],
    AK: [
        {
            name: "Alaska Board",
            url: "https://www.commerce.alaska.gov/cbp/main/search/professional",
        },
    ],
    AZ: [
        {
            name: "Arizona Board",
            url: "https://azbomv7prod.glsuite.us/GLSuiteWeb/Clients/AZBOM/public/WebVerificationSearch.aspx?q=azmd&t=20240624053911",
        },
    ],
    AR: [
        {
            name: "Arkansas Board",
            url: "https://www.armedicalboard.org/public/verify/default.aspx",
        },
    ],
    CA: [
        {
            name: "California Board",
            url: "https://search.dca.ca.gov/?BD=800&TP=8002",
        },
    ],
    CO: [
        {
            name: "Colorado Board",
            url: "https://apps2.colorado.gov/dora/licensing/lookup/licenselookup.aspx",
        },
    ],
    CT: [
        {
            name: "Connecticut Board",
            url: "https://www.elicense.ct.gov/Lookup/LicenseLookup.aspx",
        },
    ],
    DE: [
        {
            name: "Delaware Board",
            url: "https://delpros.delaware.gov/OH_VerifyLicense",
        },
    ],
    FL: [
        {
            name: "Florida Board",
            url: "https://mqa-internet.doh.state.fl.us/MQASearchServices/HealthCareProviders",
        },
    ],
    GA: [
        {
            name: "Georgia Board",
            url: "https://gcmb.mylicense.com/verification/",
        },
    ],
    HI: [
        {
            name: "Hawaii Board",
            url: "https://mypvl.dcca.hawaii.gov/public-license-search/",
        },
    ],
    ID: [
        {
            name: "Idaho Board",
            url: "https://apps-dopl.idaho.gov/IBOMPublic/LPRBrowser.aspx",
        },
    ],
    IL: [
        {
            name: "Illinois Board",
            url: "https://online-dfpr.micropact.com/lookup/licenselookup.aspx",
        },
    ],
    IN: [
        {
            name: "Indiana Board",
            url: "https://mylicense.in.gov/everification/",
        },
    ],
    IA: [
        {
            name: "Iowa Board",
            url: "https://amanda-portal.idph.state.ia.us/ibm/portal/#/license/license_query",
        },
    ],
    KS: [
        {
            name: "Kansas Board",
            url: "http://www.ksbha.org/boardactions/alpha.shtml",
        },
    ],
    KY: [
        {
            name: "Kentucky Board",
            url: "https://web1.ky.gov/GenSearch/LicenseSearch.aspx?AGY=5",
        },
    ],
    LA: [
        {
            name: "Louisiana Board",
            url: "https://online.lasbme.org/#/verifylicense",
        },
    ],
    ME: [
        {
            name: "Maine Board",
            url: "https://www.pfr.maine.gov/ALMSOnline/ALMSQuery/SearchIndividual.aspx?Board=376&AspxAutoDetectCookieSupport=1",
        },
    ],
    MD: [
        {
            name: "Maryland Board",
            url: "https://www.mbp.state.md.us/bpqapp/",
        },
    ],
    MA: [
        {
            name: "Massachusetts Board",
            url: "https://findmydoctor.mass.gov/",
        },
    ],
    MI: [
        {
            name: "Michigan Board",
            url: "https://aca-prod.accela.com/MILARA/GeneralProperty/PropertyLookUp.aspx?isLicensee=Y&TabName=APO",
        },
    ],
    MN: [
        {
            name: "Minnesota Board",
            url: "http://docfinder.docboard.org/mn/df/mndf.htm",
        },
    ],
    MS: [
        {
            name: "Mississippi Board",
            url: "https://gateway.msbml.ms.gov/verification/search.aspx",
        },
    ],
    MO: [
        {
            name: "Missouri Board",
            url: "https://pr.mo.gov/licensee-search-division.asp",
        },
    ],
    MT: [
        {
            name: "Montana Board",
            url: "https://ebizws.mt.gov/PUBLICPORTAL/searchform?mylist=licenses",
        },
    ],
    NE: [
        {
            name: "Nebraska Board",
            url: "https://www.nebraska.gov/LISSearch/search.cgi",
        },
    ],
    NV: [
        {
            name: "Nevada Board of Medical Examiners",
            url: "https://nsbme.us.thentiacloud.net/webs/nsbme/register/#",
        },
        {
            name: "Nevada Board of Osteopathic Medicine",
            url: "https://nsbom.portalus.thentiacloud.net/webs/portal/register/#/",
        },
    ],
    NH: [
        {
            name: "New Hampshire Board",
            url: "https://forms.nh.gov/licenseverification/",
        },
    ],
    NJ: [
        {
            name: "New Jersey Board",
            url: "https://newjersey.mylicense.com/verification/Search.aspx?facility=N",
        },
    ],
    NM: [
        {
            name: "New Mexico Board",
            url: "https://nmrldlpi.my.site.com/nmmb/s/searchlicense",
        },
    ],
    NY: [
        {
            name: "New York Board",
            url: "https://www.op.nysed.gov/verification-search",
        },
    ],
    NC: [
        {
            name: "North Carolina Board",
            url: "https://portal.ncmedboard.org/verification/search.aspx",
        },
    ],
    ND: [
        {
            name: "North Dakota Board",
            url: "https://www.ndbom.org/public/find_verify/verify.asp",
        },
    ],
    OH: [
        {
            name: "Ohio Board",
            url: "https://elicense.ohio.gov/OH_HomePage",
        },
    ],
    OK: [
        {
            name: "Oklahoma Board",
            url: "https://www.okmedicalboard.org/search",
        },
    ],
    OR: [
        {
            name: "Oregon Board",
            url: "https://omb.oregon.gov/search",
        },
    ],
    PA: [
        {
            name: "Pennsylvania Board",
            url: "https://www.pals.pa.gov/#!/page/search",
        },
    ],
    RI: [
        {
            name: "Rhode Island Board",
            url: "https://healthri.mylicense.com/verification/",
        },
    ],
    SC: [
        {
            name: "South Carolina Board",
            url: "https://verify.llronline.com/LicLookup/Med/Med.aspx?div=16&AspxAutoDetectCookieSupport=1",
        },
    ],
    SD: [
        {
            name: "South Dakota Board",
            url: "https://login.sdbmoe.gov/public/services/verificationsearch",
        },
    ],
    TN: [
        {
            name: "Tennessee Board",
            url: "https://apps.health.tn.gov/licensure/",
        },
    ],
    TX: [
        {
            name: "Texas Board",
            url: "https://profile.tmb.state.tx.us/Search.aspx?4c4d6aa1-9226-4ba7-b3e4-4843d6e19bec",
        },
    ],
    UT: [
        {
            name: "Utah Board",
            url: "https://secure.utah.gov/llv/search/index.html#",
        },
    ],
    VT: [
        {
            name: "Vermont Board",
            url: "https://mpb.health.vermont.gov/Lookup/LicenseLookup.aspx",
        },
    ],
    VA: [
        {
            name: "Virginia Board",
            url: "https://dhp.virginiainteractive.org/lookup/index",
        },
    ],
    WA: [
        {
            name: "Washington Board",
            url: "https://fortress.wa.gov/doh/providercredentialsearch/",
        },
    ],
    WV: [
        {
            name: "West Virginia Board",
            url: "https://wvbom.wv.gov/public/search/index.asp",
        },
    ],
    WI: [
        {
            name: "Wisconsin Board",
            url: "https://licensesearch.wi.gov/",
        },
    ],
    WY: [
        {
            name: "Wyoming Board",
            url: "https://wybomv7prod.glsuite.us/GLSuiteWeb/Clients/WYBOM/Public/Licenseesearch.aspx?SearchType=Physician",
        },
    ],
    unknown: [
        {
            name: "Unknown",
            url: "",
        },
    ],
};
