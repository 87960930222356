import { AppetiteContext } from "./AppetiteContext";
import { useContext, useState, useEffect, useMemo, ReactNode } from "react";
import { appetiteEngine } from "utils/appetiteEngine";
import { Submission } from "__generated__/graphql";
import { EvaluationResult } from "localTypes";

interface AppetiteProviderProps {
  children: ReactNode;
  submission: Submission;
}
export function AppetiteProvider({ submission, children }: AppetiteProviderProps) {
  const [evaluationResult, setEvaluationResult] = useState<EvaluationResult | null>(null);

  useEffect(() => {
    const result = appetiteEngine.evaluateSubmission(submission);
    setEvaluationResult(result || null);
  }, [submission]);

  const value = useMemo(() => ({ evaluationResult }), [evaluationResult]);

  return <AppetiteContext.Provider value={value}>{children}</AppetiteContext.Provider>;
}

export const useAppetite = () => {
  const context = useContext(AppetiteContext);
  if (context === undefined) {
    throw new Error("useAppetite must be used within an AppetiteProvider");
  }
  return context.evaluationResult;
};
