import { useState } from "react";
import { Link, Button, ButtonGroup, Box, Flex, Text } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link as RRLink, useNavigate, useParams } from "react-router-dom";
import {
  QuoteErrorCard,
  Subnav,
  ResolutionSelect,
  StatusMenu,
  QuoteResults,
  QuoteResultsCard,
  SubmissionEventDisplay,
  Loader,
} from "components";
import { useQuery } from "@apollo/client";
import { GET_SUBMISSION } from "queries";
import { Provider, StatusType, Event } from "__generated__/graphql";

interface ResolveProps {
  status?: StatusType;
  quoteResults?: QuoteResults;
  sunlightErrors?: string[];
  events?: Event[];
  loading?: boolean;
  fetchAndSaveMrs: () => Promise<void>;
}

export function Resolve({
  fetchAndSaveMrs,
  loading,
  events,
  sunlightErrors,
  quoteResults,
  status,
}: ResolveProps) {
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { data } = useQuery(GET_SUBMISSION, {
    variables: { id },
  });

  if (loading) return <Loader />;
  return (
    <div style={{ height: "100%", paddingBottom: "30px" }}>
      <Subnav
        leftContent={
          <Flex direction="column">
            <Link as={RRLink} to={`/insights/${id}`}>
              <Text lineHeight="none" fontWeight="600" color="indigo.500" fontSize="sm">
                Basic Info
                <ChevronRightIcon mt="-2px" />
              </Text>
            </Link>
            <Text lineHeight="none" fontSize="lg">
              Missing NPI
            </Text>
          </Flex>
        }
        rightContent={
          <Flex alignItems="center">
            <SubmissionEventDisplay events={events || []} status={status} />

            <Box w="1px" borderWidth="0.5px" borderColor="gray.300" height="21px" mx="16px"></Box>
            <ButtonGroup>
              <Button variant="link" colorScheme="indigo" type="submit">
                Save
              </Button>
              <StatusMenu />
              <Button
                isLoading={isSubmitting}
                data-cy="resolve-continue-button"
                colorScheme="indigo"
                onClick={async () => {
                  try {
                    setIsSubmitting(true);
                    await fetchAndSaveMrs();
                    navigate(`/insights/${id}/model-insights`);
                  } catch (e) {
                    console.error(e);
                  } finally {
                    setIsSubmitting(false);
                  }
                }}
              >
                Continue
              </Button>
            </ButtonGroup>
          </Flex>
        }
      />
      <Flex
        data-testid="resolve-page"
        w="100%"
        direction="column"
        alignItems="center"
        pr="47px"
        pt="50px"
        justifyContent="center"
      >
        <QuoteErrorCard sunlightErrors={sunlightErrors || []} />
        {quoteResults && <QuoteResultsCard quoteResults={quoteResults} />}
        <Flex
          direction="column"
          justifyContent="start"
          alignItems="center"
          w="100%"
          m="30px 30px 30px 30px"
          pl="10px"
          pr="15px"
        >
          {data &&
            data?.getSubmission?.providers?.map((provider: Provider) => {
              return (
                <ResolutionSelect
                  key={provider.id}
                  provider={provider}
                  submissionData={data?.getSubmission}
                />
              );
            })}
        </Flex>
      </Flex>
    </div>
  );
}
