import { useEffect, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { ProviderCard } from "components";
import {
  Provider,
  LocationInconsistency,
  Maybe,
  SpecialtyInconsistency,
  Factor,
} from "__generated__/graphql";
import {
  DEFAULT_MRS_VALUE,
  AVERAGE_MRS_QUOTE_THRESHOLD,
  AVERAGE_MRS_QUOTE_THRESHOLD_LARGE,
} from "app-constants";
import { useMutation } from "@apollo/client";
import {
  UPDATE_SUBMISSION,
  UPDATE_PROVIDERS,
  RESET_QUICK_AUDIT,
  TOGGLE_IGNORE_LOCATION_INCONSISTENCY,
} from "mutations";
import { GET_SUBMISSION } from "queries";
import { InsightDetails } from "./InsightDetails";
import { useModelRatingApi, useToggleQuickAudit } from "hooks";
import { Consistency, InsightDetailType } from "localTypes";
import { formatStateLicenses } from "utils";

interface ProviderCardWrapperProps {
  provider: Provider;
  providers: Provider[];
  averageAdjustedMrs: number;
  averageMRS: number;
  srfAdjustment?: number;
  bookedSRF?: number;
  index?: number;
  lengthOfSet?: number;
  scheduledRatingFactor?: number;
  modelId?: string;
  customerId?: string;
  indigoPremium?: string;
  localAdjustedPercent: number;
  refreshInsightsData: () => Promise<void>;
}

export function ProviderCardWrapper({
  providers,
  refreshInsightsData,
  localAdjustedPercent,
  provider,
  averageAdjustedMrs,
  bookedSRF,
  index,
  lengthOfSet,
  scheduledRatingFactor,
  modelId,
  indigoPremium,
}: ProviderCardWrapperProps) {
  const { modelData, fetchModelRating, loading } = useModelRatingApi();
  const threshold =
    (lengthOfSet || 0) > 1 ? AVERAGE_MRS_QUOTE_THRESHOLD_LARGE : AVERAGE_MRS_QUOTE_THRESHOLD;
  const [updateSubmission, { loading: loadingMutation }] = useMutation(UPDATE_SUBMISSION);
  const [resetQuickAudit] = useMutation(RESET_QUICK_AUDIT, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [toggleIgnoreLocationInconsistency] = useMutation(TOGGLE_IGNORE_LOCATION_INCONSISTENCY, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  const [updateProviders, { loading: loadingUpdateProviders }] = useMutation(UPDATE_PROVIDERS, {
    refetchQueries: [GET_SUBMISSION],
    awaitRefetchQueries: true,
  });
  useEffect(() => {
    if (!provider?.billingData || !provider.negativeFactors) {
      setShouldRefetchMRS(true);
    }
  }, [provider?.billingData, provider.negativeFactors]);
  const { id } = useParams();
  const { toggleQuickAudit, isQuickAuditEnabled } = useToggleQuickAudit(id || "");
  const [showInsightDetails, setShowInsightDetails] = useState(false);
  const [selectedInsightType, setSelectedInsightType] = useState<InsightDetailType>(
    InsightDetailType.Claims,
  );
  const [localProviderSpecialty, setLocalProviderSpecialty] = useState(
    isQuickAuditEnabled
      ? provider.indigoSpecialty
      : provider.manualIndigoSpecialty ?? provider.indigoSpecialty,
  );
  useEffect(() => {
    if (isQuickAuditEnabled && provider.manualIndigoSpecialty !== provider.indigoSpecialty) {
      setLocalProviderSpecialty(provider.indigoSpecialty);
    } else if (!isQuickAuditEnabled) {
      setLocalProviderSpecialty(provider.manualIndigoSpecialty ?? provider.indigoSpecialty);
    }
  }, [
    isQuickAuditEnabled,
    provider.indigoSpecialty,
    provider.manualIndigoSpecialty,
    localProviderSpecialty,
    setLocalProviderSpecialty,
  ]);
  const validMrs = isQuickAuditEnabled ? provider.mrs : provider.manualMrs || provider.mrs;
  const [shouldRefetchMRS, setShouldRefetchMRS] = useState(false);

  const updateSubmissionModelData = useCallback(async () => {
    if (modelData?.metadata?.modelId && modelData?.metadata?.modelId !== modelId) {
      const newModelId = modelData?.metadata?.modelId || "Unknown";
      const newModelVersion = modelData?.metadata?.modelVersion || "Unknown";
      try {
        await updateSubmission({
          variables: {
            input: {
              id,
              quoteProperties: {
                modelInfo: {
                  modelId: newModelId,
                  modelVersion: newModelVersion,
                },
              },
            },
          },
        });
      } catch (e) {
        console.error(e);
      }
    }
  }, [
    id,
    modelData?.metadata?.modelId,
    modelData?.metadata?.modelVersion,
    updateSubmission,
    modelId,
  ]);

  useEffect(() => {
    if (modelData) {
      updateSubmissionModelData();
    }
  }, [modelData, updateSubmissionModelData]);

  const refetchMrs = useCallback(async () => {
    await fetchModelRating({ ...provider, indigoSpecialty: localProviderSpecialty });
    await refreshInsightsData();
  }, [refreshInsightsData, localProviderSpecialty, fetchModelRating, provider]);

  useEffect(() => {
    if (shouldRefetchMRS) {
      refetchMrs();
      setShouldRefetchMRS(false);
    }
  }, [refetchMrs, shouldRefetchMRS]);

  return (
    <div>
      <ProviderCard
        id={provider.id}
        isLocked={isQuickAuditEnabled}
        isNewToPractice={provider.newToPractice === 1}
        isLoading={loading || loadingMutation || loadingUpdateProviders}
        indigoPremium={indigoPremium}
        index={index || 0}
        lengthOfSet={lengthOfSet || 1}
        bookedSRF={bookedSRF || 0}
        adjustedPercent={parseFloat(localAdjustedPercent?.toFixed(2))}
        adjustedMRS={averageAdjustedMrs || 0}
        handleChangeSpecialty={async (e) => {
          const selectedSpecialty = e.target.value;
          try {
            setLocalProviderSpecialty(e.target.value);
            const validPreviewPremium = isQuickAuditEnabled
              ? "previewPremium"
              : "manualPreviewPremium";
            const validIndigoSpecialty = isQuickAuditEnabled
              ? "indigoSpecialty"
              : "manualIndigoSpecialty";
            const hasPreviewPremium = !!provider[validPreviewPremium];
            const updatedProviders = providers?.map((p) => {
              if (p.id === provider.id) {
                return {
                  id: p.id,
                  manualIndigoSpecialty: selectedSpecialty,
                  scheduledRatingFactor,
                };
              }
              return {
                id: p.id,
                manualIndigoSpecialty: p[validIndigoSpecialty],
              };
            });
            await updateProviders({
              variables: {
                submissionId: id,
                providers: updatedProviders,
              },
            });
            await updateSubmission({
              variables: {
                input: {
                  id,
                  useCalculatedSrfAdjustment: true,
                  manualPreviewPremiumsAreStale:
                    (hasPreviewPremium && provider[validIndigoSpecialty] !== selectedSpecialty) ===
                    true
                      ? true
                      : undefined,
                },
              },
            });
            if ((isQuickAuditEnabled ?? true) && provider.indigoSpecialty !== selectedSpecialty) {
              await toggleQuickAudit(false);
            }
            setShouldRefetchMRS(true);
          } catch (e) {
            console.error(e);
          }
        }}
        name={`${provider.firstName} ${provider.lastName}`}
        mrs={validMrs}
        mrsThreshold={threshold}
        boardActionsCount={provider.boardActions?.length || 0}
        claimsCount={provider?.claims?.length || 0}
        redFlagsCount={provider.redFlags?.length || 0}
        redFlagSearchCompleted={provider.redFlagSearchCompleted}
        consistency={
          (provider?.inconsistencies?.location?.filter(
            (c: Maybe<LocationInconsistency>) => c?.isConsistent === false,
          )?.length || 0) > 0 ||
          (provider?.inconsistencies?.specialty?.filter(
            (c: Maybe<SpecialtyInconsistency>) => c?.isConsistent === false,
          )?.length || 0) > 0
            ? Consistency.Low
            : Consistency.High
        }
        county={provider.address?.countyName || ""}
        npi={provider.npi || ""}
        specialty={localProviderSpecialty || ""}
        suffix={provider.suffix || ""}
        customerId={provider.simplifyCustomerId || ""}
        showInsightModal={(type: InsightDetailType) => {
          setSelectedInsightType(type);
          setShowInsightDetails(true);
        }}
      />
      {showInsightDetails && (
        <InsightDetails
          id={provider.id}
          redFlags={provider.redFlags || []}
          hasLargeGroup={(lengthOfSet && lengthOfSet > 1) || false}
          stateLicenses={provider.stateLicenses ? formatStateLicenses(provider.stateLicenses) : []}
          isIgnoringLocationInconsistency={
            isQuickAuditEnabled === false ? provider?.ignoreLocationInconsistency : true
          }
          setIgnoreLocationInconsistency={async () => {
            try {
              if (isQuickAuditEnabled) {
                await resetQuickAudit({
                  variables: {
                    submissionId: id,
                  },
                });
              }
              await toggleIgnoreLocationInconsistency({
                variables: {
                  submissionId: id,
                  providerId: provider.id,
                },
              });
              setShouldRefetchMRS(true);
            } catch (e) {
              console.error(e);
            }
          }}
          boardActions={provider.boardActions || []}
          factors={(provider?.negativeFactors || []) as Factor[]}
          mrs={validMrs || DEFAULT_MRS_VALUE}
          mrsTrend={provider.mrsTrends}
          payerData={provider?.billingData?.payerData || []}
          hvData={provider?.billingData?.hvData || []}
          locationInconsistencies={provider?.inconsistencies?.location}
          specialtyInconsistencies={provider?.inconsistencies?.specialty}
          type={selectedInsightType}
          claims={provider?.claims || []}
          name={`${provider.firstName} ${provider.lastName}`}
          onClose={() => setShowInsightDetails(false)}
        />
      )}
    </div>
  );
}
